export default function Loader(){

return (


<div className="loader-container">

<h3>Loading.....</h3>

</div>



);



}
